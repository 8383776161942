'use strict';

class TopTableController {

  constructor($scope) {
    this.sortReverse = false;
    this.searchString = '';
    this.$scope = $scope;
    var ctrl = this;
    var sorted = false;
    $scope.$watch('objects', function() {
      if ($scope.objects.length>0 && !sorted){
        ctrl.sortTable($scope.tableStructure[0].value);
        sorted = true;
      }
    });
  }

  filterObjects() {
    if (this.searchString === '') {
      return this.$scope.objects;
    }

    var filteredObjects = [];
    for (var i=0 ; i<this.$scope.objects.length ; i++) {
      var object = this.$scope.objects[i];
      var respectsFilter = false;

      for (var j=0 ; j<this.$scope.tableStructure.length ; j++) {
        if ( this.$scope.tableStructure[j].filter === 'alpha') {
          if ( object[this.$scope.tableStructure[j].value].toLowerCase().indexOf(this.searchString.toLowerCase()) > -1 ) {
            respectsFilter=true;
          }
        } else if (this.$scope.tableStructure[j].filter === 'num') {
          if (parseInt(this.searchString) === object[this.$scope.tableStructure[j].value]) {
            respectsFilter=true;
          }
        }
      }

      if (respectsFilter) {
        filteredObjects.push(object);
      }
    }
    return filteredObjects;
  }

 sortTable(type) {

    if (this.sortType === type) {
      this.sortReverse = !this.sortReverse;
    }
    this.sortType = type;
    var reverse = !this.sortReverse;

   this.$scope.objects.sort( (a, b) => {
     const quantityA = parseInt(a[type].replace(/\D/g, ""))
     const quantityb = parseInt(b[type].replace(/\D/g, ""))
     return  quantityb - quantityA;
   })
  }

  getObjectValue(object, col){
    if (col.filter === 'date') {
      return object[col.value].toDateString() + ' - ' + object[col.value].toLocaleTimeString().slice(0,11);
    } else {
      return object[col.value];
    }
  }


}

angular.module('cpformplastApp')
  .controller('TopTableController', TopTableController);
